import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';

export class ViewRef {
  private readonly _afterClosed$: Subject<boolean | undefined> = new Subject<boolean | undefined>();

  constructor(private readonly _overlayRef: OverlayRef) {}

  afterClosed(): Observable<boolean | undefined> {
    return this._afterClosed$.asObservable();
  }

  dismiss(result?: boolean): void {
    this._overlayRef.detachBackdrop();
    this._overlayRef.dispose();

    this._afterClosed$.next(result);
    this._afterClosed$.complete();
  }
}
